import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import ExtinctionSwarmViz from '../components/chart-extinction';
import ExtinctionOpenGraphImage from '../images/social-extinction.jpg';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "extinction-story.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="The Ancient Roots of Modern Extinction" description="Extinctions marked by human influence are not new. They began in the stone age." image={'https://storybook.earth' + ExtinctionOpenGraphImage} pageCanonical={'https://storybook.earth/extinction'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content">
      <p className="txt-low-emph txt-center">Human and Giant Moa (extinct) footprints</p>
      <div className="txt-center mvxxl">
    <h1 className="phm">The Ancient Roots of Modern Extinction</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Jul 21, 2020 | 5 min read</p>
    </div>
      </div>
      <p>{`A tour of America 12,000 years ago would feel like an African safari with animals like saber toothed tigers, camels, giant sloths, and mastodons roaming the wilderness. All of them disappeared in a series of extinctions that began tens of thousands of years ago and continues today. Fossil evidence suggests that humans played a substantive role at the onset of these extinctions using only stone age technologies to take down prey. Today, widespread industrial food production has accelerated the rate of extinctions, threatening not only human prey animals but entire ecosystems `}<span className="sup">{`[1]`}</span>{`.`}</p>
      <p>{`When humans began migrating around the globe some 50,000 years ago a pattern began. People would migrate to a new location and the disappearance of local animal populations would follow, in some cases within decades `}<span className="sup">{`[2]`}</span>{`. Australia is an early example. The arrival of humans 40,000 years ago was followed by a surge of local extinctions not explained by climate change or other factors. William Ruddiman, a paleoclimatologist and professor emeritus at the University of Virginia, inventories the animals lost: "...a marsupial lion, three kinds of wombat, nine genera of kangaroo, and a range of nonmarsupials... Every vertebrate species larger than humans disappeared. Nothing even close to this concentrated a pulse of extinction had occurred for millions of years on Australia, despite an ongoing drift of climate toward much drier conditions" `}<span className="sup">{`[3]`}</span>{`.`}</p>
      <p>{`As in Australia, the pattern of ancient human migration elsewhere was followed by the disappearance of some of the largest animals on Earth: flightless birds that were twelve feet tall, giant sloths the size of elephants, spike-tailed armadillo relatives that weighed as much as a Fiat, and deer with antlers spanning nine feet. Often referred to collectively as the prehistoric megafauna, most of these animals had been geographically isolated from humans for millennia and evolved few defense mechanisms to combat the new invasive threat. With long reproductive cycles and few offspring, megafauna like mastodons and giant sloths were particularly vulnerable to over hunting`}<span className="sup">{` `}{`[4]`}</span>{`.`}</p>
      <p>{`Humans also brought tagalongs wherever they traveled that may have been just as deadly to the new environments they encountered. Diseases and other invasive species like rats and foxes were introduced by humans as they explored the planet, often intentionally, wreaking havoc on unprepared ecosystems`}<span className="sup">{` `}{`[2]`}</span>{`.`}</p>
      <div className="mtxxl txt-center">
        <h3>Timeline of Animal Extinctions in the last 12,000 Years</h3>
        <h4 className="title-font mts mbn">Mouse over or tap on a circle for species info</h4>
        <ExtinctionSwarmViz mdxType="ExtinctionSwarmViz" />
        <div className="davi-chart-bottom">
    <div className="x-axis-label">Years Before Present</div>
        </div>
        <p className="phxl mbxxl caption">This timeline plots animal extinctions from 12,000 years ago to the present, grouped and colored by taxonomic class. The dataset includes species from the IUCN Red List <span className="sup"> [5]</span>, Wikipedia <span className="sup"> [6][7]</span>, and New Zealand Birds Online <span className="sup"> [8]</span>.</p>
      </div>
      <p>{`The timeline above is far from comprehensive. It contains just a tiny sample of the biodiversity that has disappeared from the Earth in what amounts to an instant in geologic time. Producing a complete picture is impossible, in part because there is no agreement among scientists on how many species there are on the planet at a given point in time `}<span className="sup">{` `}{`[9]`}</span>{`, and also because small or soft-bodied organisms rarely leave behind evidence of their existence in the fossil record `}<span className="sup">{` `}{`[10]`}</span>{`.`}</p>
      <p>{`While incomplete, the timeline is accurate to suggest that the rate of extinctions is increasing, fast. Humans drive climate change and alter the landscape at unprecedented rates, which together disrupt ecosystems on both the local and global scale simultaneously. While some species may appear to benefit, the rapid disappearance of so many others means they will inherit a world degraded in both diversity and vitality.`}</p>
      <p>{`Shahid Naeem, the chair of Columbia University's Department of Ecology, Evolution, and Environmental Biology offers some perspective: "We’ve already lost several million species over the past two or three hundred years, which means that we’re on course to reduce the earth’s total biodiversity by 75 percent or more"`}<span className="sup">{` `}{`[11]`}</span>{`.`}</p>
      <p>{`75 percent may seem arbitrary, but the implications are profound. "If you lose 75 percent of all species — which is the scientific definition of a mass-extinction event — what emerges is an entirely different world. The climate is going to be different, the dominant life forms are going to be different, and even the color of the sky could be different, since that blue hue is the result of sunlight being scattered by oxygen molecules and other particles produced by vegetation. Our planet has experienced a total of five previous mass-extinction events, and the fossil record shows the average recovery time is about ten million years"`}<span className="sup">{` `}{`[11]`}</span>{`.`}</p>
      <div className="references mvxxl">
    <strong>References</strong>
    <ol>
    <li>Jeff Tollefson, <a href="https://www.nature.com/articles/d41586-019-01448-4" target="blank">Humans are Driving one Million Species to Extinction</a>, Nature, 2019</li>
    <li>Ross D E MacPhee and Peter Schouten, <a href="https://wwnorton.com/books/9780393249293" target="blank">End of the Megafauna</a>, WW Norton and Company, 2018 </li>
    <li>William F. Ruddiman, <a href="https://press.princeton.edu/books/paperback/9780691173214/plows-plagues-and-petroleum" target="blank">Plows, Plagues, and Petroleum: How Humans Took Control of Climate</a>, Princeton University Press, 2017 </li>
    <li>Elizabeth Kolbert, <a href="https://www.pulitzer.org/winners/elizabeth-kolbert" target="blank">The Sixth Extinction: An Unnatural History</a>, Henry Holt and Company, 2014</li>
    <li><a href="https://www.iucnredlist.org" target="blank">IUCN Red List of Threatened Species</a>, IUCN, Feb 2020. Accessed: Feb 11, 2020</li>
    <li><a href="https://en.wikipedia.org/wiki/Timeline_of_extinctions_in_the_Holocene" target="blank">Timeline of extinctions in the Holocene</a>, Wikipedia, Jan 2020. Accessed: Feb 11, 2020</li>
    <li><a href="https://en.wikipedia.org/wiki/List_of_North_American_animals_extinct_in_the_Holocene" target="blank">List of North American Animals Extinct in the Holocene</a>, Wikipedia, Feb 2020. Accessed: Feb 11, 2020</li>
    <li><a href="http://nzbirdsonline.org.nz/status-search?field_status_term_ids=95&field_status_term_value=Extinct" target="blank">Extinct Birds of New Zealand</a>, New Zealand Birds Online, 2013, Accessed: Feb 11, 2020</li>
    <li>Fred Pearce, <a href="https://e360.yale.edu/features/global_extinction_rates_why_do_estimates_vary_so_wildly" target="blank"> Global Extinction Rates: Why Do Estimates Vary So Wildly?</a>, Environment 360, Yale School of Forestry & Environmental Studies, 2015</li>
    <li>Douglas Erwin, <a href="https://press.princeton.edu/books/paperback/9780691165653/extinction" target="blank">Extinction: How Life on Earth Nearly Ended 250 Million Years Ago</a>, Princeton University Press, 2015</li>
    <li>David J. Craig, <a href="https://magazine.columbia.edu/article/what-everyone-needs-know-about-threat-mass-extinction" target="blank">What Everyone Needs to Know About the Threat of Mass Extinction</a>, Columbia Magazine, 2019</li>
    </ol>
      </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      